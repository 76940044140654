import React from 'react';
import styles from './Footer.module.css';
import {NavLink} from 'react-router-dom';


const Footer = () => (
    <div className={styles.footer}>
        <div className={styles.email}>info@1-foto.com</div>
        <div className={styles.rights}>1-Foto. all rights reserved ©</div>
        <div className={styles.credits}>Developed by: <a href="https://github.com/grimgoon/">Alexander Hermansson</a></div>
        <NavLink 
            exact
            className={styles.link} 
            activeClassName={styles.active}
            to="/admin">
            Admin
        </NavLink>
    </div>
) 

export default Footer;